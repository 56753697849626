export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getPaginationOptions(length: number) {
    if (length <= 10) {
        return [];
    }

    const options: number[] = [1, 5, 10, 25, 50, 100, 200, 500, 1000, 2000, 2500, 5000].filter(option => option <= length);
    if (!options.includes(length)) {
        options.push(length);
    }
    return options;
}