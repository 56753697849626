import words from "./Words";
import alphabet from "./Alphabet";
import { koreanNumbers, sinoKoreanNumbers } from "./Numbers";
import { shuffleArray } from "./helpers";
import { Alphabet, CombinedResourceList, KoreanNumber, SinoKoreanNumber, Word } from "./dto/types";
import { ResourceType } from "./dto/enums";

export const combinedResources: CombinedResourceList[] = [
    ...words,
    ...alphabet,
    ...koreanNumbers,
    ...sinoKoreanNumbers,
];

export const shuffleCombinedResources = shuffleArray(combinedResources);

export function isWord(resource: any): resource is Word {
    return resource && resource.resource === ResourceType.Word;
}

export function isAlphabet(resource: any): resource is Alphabet {
    return resource && resource.resource === ResourceType.Alphabet;
}

export function isNumber(resource: any): resource is (KoreanNumber | SinoKoreanNumber) {
    return resource && resource.resource === ResourceType.Number;
}