import React from 'react';
import { Select, Card, CardContent, Divider, ListItemIcon, ListItemText, MenuItem, Switch, Typography } from '@mui/material';
import TungstenIcon from '@mui/icons-material/Tungsten';
import TranslateIcon from '@mui/icons-material/Translate';
import PublicOutlined from '@mui/icons-material/PublicOutlined';
import { RoutesOptionsProps } from '../dto/types';
import { ColorMode } from '../dto/enums';

export const Options: React.FC<RoutesOptionsProps> = (
  { colorMode, setColorMode, backgroundSeasonal, setBackgroundSeasonal, showRomanisation, setShowRomanisation }) => {
  console.log(colorMode, setColorMode, backgroundSeasonal, setBackgroundSeasonal, showRomanisation, setShowRomanisation);
  return (
    <>
      <Card className='max-width'>
        <CardContent>
          <Typography variant="h4">
            Opties
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Pas de instellingen van de app aan.
          </Typography>
          <MenuItem>
            <ListItemIcon>
              <TungstenIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Algemeen voorkomen" /><br />
            <ListItemText secondary="Heeft effect op achtergrond, forms, text" />
            <Select
              value={colorMode}
              onChange={(event) => setColorMode(event.target.value as ColorMode)}
              name="Algemeen voorkomen"
              size="small"
              inputProps={{ 'aria-label': 'toggle Algemeen voorkomen' }}
            >
              {Object.values(ColorMode).map((mode) => (
                <MenuItem value={mode}>{mode.charAt(0).toUpperCase() + mode.slice(1)}</MenuItem>
              ))}
            </Select>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setBackgroundSeasonal(!backgroundSeasonal)}>
            <ListItemIcon>
              <PublicOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Seizoens achtergrond" />
            <ListItemText secondary="Elk seizoen een andere tempel als achtergrond" />
            <Switch
              checked={backgroundSeasonal}
              onChange={() => setBackgroundSeasonal(!backgroundSeasonal)}
              name="Seizoens achtergrond"
              inputProps={{ 'aria-label': 'toggle background seasonal' }}
            />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setShowRomanisation(!showRomanisation)}>
            <ListItemIcon>
              <TranslateIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Romanisatie" />
            <ListItemText secondary="Fonetisch Koreaans" />
            <Switch
              checked={showRomanisation}
              onChange={() => setShowRomanisation(!showRomanisation)}
              name="Romanisation"
              inputProps={{ 'aria-label': 'toggle romanisation' }}
            />
          </MenuItem>
        </CardContent>
      </Card>
    </>
  );
};
