import { SvgIcon, SvgIconProps } from '@mui/material';

const HangulIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
	<path d="M10.68,6.23c1.48-0.29,0.79-0.74,2.96-0.77c0.92,0.03,1.37,0.4,1.37,0.74c0,0.32-0.4,0.63-1.11,0.63
		c-2.24,0.03-4.33,0.11-5.76,0.21c0.45,0.5,0.55,0.74,0.55,0.9c0,0.16-0.11,0.26-0.13,0.5c2.91,0.4,4.25,2.09,4.25,3.8
		c0,2.03-1.82,3.86-4.91,3.86c-3.3,0-5.02-2.03-5.02-3.86c0-1.72,1.37-3.41,4.36-3.8C7.43,8.29,7.54,8.1,7.54,7.92
		c0-0.26-0.16-0.53-0.42-0.79c-0.9,0-1.93,0.08-3.3,0.26C3.29,7.5,3.15,7.71,2.84,7.71c-0.5,0-2.51-1.11-2.51-1.4
		c0-0.08,0.11-0.13,0.34-0.13c0.13,0,0.32,0.03,0.53,0.05c1.16,0.18,2.3,0.26,3.72,0.26C6.61,6.49,8.09,6.41,10.68,6.23z M6.32,4.04
		c-0.53,0-2.19-1.19-2.19-1.51c0-0.08,0.05-0.11,0.24-0.11c0.13,0,0.34,0.03,0.61,0.08c1.14,0.16,1.51,0.16,4.78,0.16
		c0.71,0.05,1.11,0.34,1.11,0.63c0,0.32-0.45,0.63-1.45,0.66L6.32,4.04z M4.47,12.14c0,1.45,1.13,2.9,3.41,2.9
		c2.27,0,3.41-1.45,3.41-2.9s-1.14-2.9-3.41-2.9C5.61,9.24,4.47,10.69,4.47,12.14z M7.11,19.41c-0.05-0.5-0.66-0.77-1.85-1.16
		c-0.4-0.08-0.66-0.24-0.66-0.37c0-0.13,0.24-0.26,0.84-0.32c1.24,0,2.32,0.11,2.83,0.18c0.48,0.13,0.58,0.26,0.58,0.5
		c0,0.16-0.08,0.4-0.08,0.69v2.98c0,0.63,0.34,0.9,1.24,0.9l5.68-0.05c1.8-0.05,0.98-0.77,3.27-0.77c0.66,0,2.06,0,2.06,0.82
		c0,0.34-0.4,0.69-1.08,0.69H9.97c-1.77,0-2.85-0.53-2.85-1.77V19.41z M16.62,2.64c0-0.29-0.69-0.77-2.27-1.45
		c-0.4-0.13-0.61-0.29-0.61-0.4c0-0.16,0.32-0.26,0.95-0.29c0.11,0,3.72,0.45,3.72,1.32c0,0.18-0.11,0.4-0.11,0.66l-0.03,8.29
		c0.37-0.03,0.79,0,1.19-0.05c1.21-0.24,0.66-0.74,2.69-0.74c0.53,0,1.51,0,1.51,0.71c0,0.34-0.29,0.66-0.77,0.66l-4.65,0.11
		c-0.03,4.78-0.19,5.97-0.45,6.71c-0.24,0.5-0.53,0.9-0.77,0.9c-0.24,0-0.4-0.32-0.42-1.19V2.64z"/>
    </SvgIcon>
);

export default HangulIcon;