import { useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import { RoutesWrapper } from "./Routes";
import Navigation from './components/Navigation';
import TabBottomNavigation from './components/TabBottomNavigation';
import { useSeasonalBackground, useColorMode } from './hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './scss/App.scss';
import React from "react";
import { ColorMode } from "./dto/enums";

const blue = getComputedStyle(document.documentElement).getPropertyValue('--blue').trim();
const red = getComputedStyle(document.documentElement).getPropertyValue('--red').trim();
const green = getComputedStyle(document.documentElement).getPropertyValue('--green').trim();
const yellow = getComputedStyle(document.documentElement).getPropertyValue('--yellow').trim();
const black = getComputedStyle(document.documentElement).getPropertyValue('--black').trim();
const white = getComputedStyle(document.documentElement).getPropertyValue('--white').trim();
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim();

export const Colors = {
  Blue: blue,
  Red: red,
  Green: green,
  Yellow: yellow,
  Black: black,
  White: white,
  Primary: primaryColor,
  Secondary: secondaryColor
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Primary,
    },
    secondary: {
      main: Colors.Secondary,
    },
    text: {
      primary: Colors.Black,
      secondary: Colors.Black,
    },
    background: {
      default: Colors.White,
    },
  },
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: Colors.Black,
          "&:hover": {
            color: Colors.Black
          },
          "&.Mui-active": {
            "&&": {
              color: Colors.Primary,

              "& * ": {
                color: Colors.Secondary,
              }
            }
          }
        },
        icon: {
          color: Colors.White
        }
      }
    }
  }
});


const App = () => {
  const [showRomanisation, setShowRomanisation] = useState(true);
  const [backgroundSeasonal, setBackgroundSeasonal] = useState(true);
  const [colorMode, setColorMode] = React.useState<ColorMode>(ColorMode.System);

  useSeasonalBackground(backgroundSeasonal);

  useColorMode(colorMode);

  return (
    <div className={"app-wrapper"}>
      <ThemeProvider theme={theme}>
        <Router>
          <Navigation colorMode={colorMode}
            setColorMode={setColorMode}
            showRomanisation={showRomanisation}
            setShowRomanisation={setShowRomanisation}
            backgroundSeasonal={backgroundSeasonal}
            setBackgroundSeasonal={setBackgroundSeasonal} />
          <section id="content">
            <RoutesWrapper
              colorMode={colorMode}
              setColorMode={setColorMode}
              showRomanisation={showRomanisation}
              setShowRomanisation={setShowRomanisation}
              backgroundSeasonal={backgroundSeasonal}
              setBackgroundSeasonal={setBackgroundSeasonal}
            />
          </section>
          <TabBottomNavigation
            colorMode={colorMode}
            setColorMode={setColorMode}
            showRomanisation={showRomanisation}
            setShowRomanisation={setShowRomanisation}
            backgroundSeasonal={backgroundSeasonal}
            setBackgroundSeasonal={setBackgroundSeasonal}
          />
        </Router>
      </ThemeProvider>
    </div >
  );
}

export default App;