export enum NumberType {
    Korean = "Korean",
    SinoKorean = "Sino-Koreaans",
};

export enum ResourceType {
    Word = 'woorden',
    Alphabet = 'letters',
    Number = 'telsystemen',
};

export enum AlphabetType {
    Klinker = "Klinker",
    DubbeleKlinker = "Dubbele Klinker",
    Medeklinker = "Medeklinker",
    AspiratieMedeklinker = "Aspiratie Medeklinker",
    GespannenMedeKlinker = "Gespannen Medeklinker",
};

export enum ColorMode {
    Light = 'light',
    Dark = 'dark',
    System = 'system',
}

export enum Severity {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Default = 'default',
}