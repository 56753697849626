import { useEffect } from 'react';

import { ColorMode } from '../dto/enums';

export const useColorMode = (colorMode: ColorMode) => {
  useEffect(() => {
    document.body.classList.remove(ColorMode.Dark, ColorMode.Light);

    if (colorMode === ColorMode.System) {
      document.body.classList.add(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? ColorMode.Dark : ColorMode.Light);
    } else {
      document.body.classList.add(colorMode);
    }
  }, [colorMode]);
};
