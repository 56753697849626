import React from 'react';
import { useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BottomNavigation, BottomNavigationAction, CssBaseline, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { getRoutesOptions } from "../Routes";
import { RouteOption, RoutesOptionsProps } from '../dto/types';

export const TabBottomNavigation: React.FC<RoutesOptionsProps> = (
    { colorMode: colorMode, setColorMode: setColorMode, showRomanisation, setShowRomanisation, backgroundSeasonal,
        setBackgroundSeasonal }) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = React.useState(0);
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const routeOptions = getRoutesOptions({ colorMode: colorMode, setColorMode: setColorMode, showRomanisation, setShowRomanisation, backgroundSeasonal, setBackgroundSeasonal });

    return (
        <>
            <CssBaseline />
            <Paper className="bottomnavbar" sx={{ position: 'fixed', bottom: 7, left: 7, right: 7 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={location.pathname}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    {routeOptions.filter((route: RouteOption) => !!route.inBottomNav).map((route: RouteOption) => (
                        <BottomNavigationAction sx={{ minWidth: "40px" }}
                            // label={route.title}
                            icon={route.icon}
                            component={Link}
                            to={route.path}
                            value={route.path}
                        />
                    ))}

                    <BottomNavigationAction
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        icon={<MoreVertIcon />}
                        style={{ maxWidth: "30px" }}
                    />
                </BottomNavigation>
            </Paper>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {routeOptions.filter((route: RouteOption) => !!route.isSetting).map((route: RouteOption, index, array) => (
                    <React.Fragment key={route.path}>
                        <MenuItem component="a" href={route.path} onClick={handleClose}>
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <ListItemText primary={route.title} />
                        </MenuItem>
                        {index !== array.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </Menu>
        </>
    );
}
export default TabBottomNavigation;