import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import TranslateIcon from '@mui/icons-material/Translate';
import ExerciseIcon from '@mui/icons-material/FitnessCenter';
import MultipleChoiceIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import WritingIcon from '@mui/icons-material/Create';
import SpeakingIcon from '@mui/icons-material/Mic';
import PinIcon from '@mui/icons-material/PinOutlined';
import FillIcon from '@mui/icons-material/FormatColorFill';
import FinishedIcon from '@mui/icons-material/CheckCircleOutline';
import StyleIcon from '@mui/icons-material/StyleOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

import HangulIcon from './assets/HangulIcon';
import { ResetCheatModeOnRouteChange } from './hooks';
import { RoutesOptionsProps, RouteOption } from './dto/types';

import { FillInTheBlanks, MemoryGame, MultipleChoice, SpeakingExercise, WritingExercise } from './Excercises/';

import { Options, Colofon, Exercise, FlashCards, HangulAlphabet, Introduction, NumberList, WordList, Translator, Finished } from './pages/';

const FinishedPageWrapper: React.FC = () => {
    const { score, correctAnswers, wrongAnswers, cheatMode } = useParams();
    return <Finished score={Number(score)} correctAnswers={Number(correctAnswers)} wrongAnswers={Number(wrongAnswers)} cheatMode={Boolean(cheatMode)} />;
};

const getSettingsRoutesOptions = (
    { colorMode,
        setColorMode,
        backgroundSeasonal,
        setBackgroundSeasonal,
        showRomanisation,
        setShowRomanisation }: RoutesOptionsProps
): RouteOption[] => [
        {
            path: "/opties",
            title: "Opties",
            element: <Options
                colorMode={colorMode}
                setColorMode={setColorMode}
                backgroundSeasonal={backgroundSeasonal}
                setBackgroundSeasonal={setBackgroundSeasonal}
                showRomanisation={showRomanisation}
                setShowRomanisation={setShowRomanisation}
            />,
            inBottomNav: false,
            inDrawer: false,
            isExercise: false,
            isSetting: true,
            icon: <SettingsIcon />,
            enabled: true
        },
        {
            path: "/colofon",
            title: "Colofon",
            element: <Colofon />,
            inBottomNav: false,
            inDrawer: false,
            isExercise: false,
            isSetting: true,
            icon: <InfoIcon />,
            enabled: true
        },
    ];

export const getRoutesOptions = ({
    colorMode,
    setColorMode,
    backgroundSeasonal,
    setBackgroundSeasonal,
    showRomanisation,
    setShowRomanisation
}: RoutesOptionsProps): RouteOption[] => {
    const excerciseRoutes: RouteOption[] = [
        { path: "/multiple-choice", title: "Multiple Choice", element: <MultipleChoice />, inBottomNav: false, inDrawer: false, isExercise: true, icon: <MultipleChoiceIcon />, enabled: true },
        { path: "/memory", title: "Memory", element: <MemoryGame />, inBottomNav: false, inDrawer: false, isExercise: true, icon: <MultipleChoiceIcon />, enabled: true },
        { path: "/schrijven", title: "Writing Exercise", element: <WritingExercise />, inBottomNav: false, inDrawer: false, isExercise: true, icon: <WritingIcon />, enabled: true },
        { path: "/spreken", title: "Speaking Exercise", element: <SpeakingExercise />, inBottomNav: false, inDrawer: false, isExercise: true, icon: <SpeakingIcon />, enabled: true },
        { path: "/invullen", title: "Fill in The Blanks", element: <FillInTheBlanks />, inBottomNav: false, inDrawer: false, isExercise: true, icon: <FillIcon />, enabled: true },
    ];

    const routes: RouteOption[] = [
        { path: "/", title: "Introduction", element: <Introduction />, inBottomNav: false, inDrawer: false, isExercise: false, icon: <HomeIcon />, enabled: true },
        { path: "/Alfabet", title: "Alfabet", element: <HangulAlphabet showRomanisation={showRomanisation || false} />, inBottomNav: true, inDrawer: false, isExercise: false, icon: <HangulIcon />, enabled: true },
        { path: "/woorden-lijst", title: "Woordenlijst", element: <WordList showRomanisation={showRomanisation || false} />, inBottomNav: true, inDrawer: false, isExercise: false, icon: <ListIcon />, enabled: true },
        { path: "/flash-cards", title: "Flashcards", element: <FlashCards showRomanisation={showRomanisation || false} />, inBottomNav: true, inDrawer: false, isExercise: false, icon: <StyleIcon />, enabled: true },
        { path: "/nummers", title: "Nummers", element: <NumberList showRomanisation={showRomanisation || false} />, inBottomNav: true, inDrawer: false, isExercise: false, icon: <PinIcon />, enabled: true },
        { path: "/vertaler", title: "Vertalen", element: <Translator />, inBottomNav: true, inDrawer: false, isExercise: false, icon: <TranslateIcon />, enabled: false },
        {
            path: "/oefeningen", title: "oefeningen", element: <Exercise
                showRomanisation={showRomanisation}
                colorMode={colorMode}
                setColorMode={setColorMode}
                backgroundSeasonal={backgroundSeasonal}
                setBackgroundSeasonal={setBackgroundSeasonal}
                setShowRomanisation={setShowRomanisation} />, inBottomNav: true, inDrawer: false, isExercise: false, icon: <ExerciseIcon />, enabled: true
        },
        ...excerciseRoutes,
        { path: "/finished/:score/:correctAnswers/:wrongAnswers/:cheatMode", title: "Uitslag", element: <FinishedPageWrapper />, inBottomNav: false, inDrawer: false, isExercise: false, icon: <FinishedIcon />, enabled: true },
        ...getSettingsRoutesOptions({
            colorMode,
            setColorMode,
            backgroundSeasonal,
            setBackgroundSeasonal,
            showRomanisation,
            setShowRomanisation
        })
    ].map((route: RouteOption) => ({
        ...route,
        inBottomNav: route.inBottomNav ?? false,
        inDrawer: route.inDrawer ?? false,
        isExercise: route.isExercise ?? false,
        isSetting: route.isSetting ?? false,
    })).filter(route => route.enabled);

    return routes;
};

export default getRoutesOptions;

export const RoutesWrapper: React.FC<RoutesOptionsProps> = ({
    colorMode,
    setColorMode,
    backgroundSeasonal,
    setBackgroundSeasonal,
    showRomanisation,
    setShowRomanisation
}: RoutesOptionsProps) => {
    const RoutesOptions = getRoutesOptions({
        colorMode,
        setColorMode,
        backgroundSeasonal,
        setBackgroundSeasonal,
        showRomanisation,
        setShowRomanisation
    });

    return (
        <>
            <ResetCheatModeOnRouteChange />
            <Routes>
                {RoutesOptions.map((route: RouteOption) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                ))}
            </Routes>
        </>
    );
}

export function isAnyRouteInDrawerEnabled({
    colorMode,
    setColorMode,
    backgroundSeasonal,
    setBackgroundSeasonal,
    showRomanisation,
    setShowRomanisation
}: RoutesOptionsProps) {
    return getRoutesOptions({
        colorMode: colorMode,
        setColorMode: setColorMode,
        backgroundSeasonal,
        setBackgroundSeasonal,
        showRomanisation,
        setShowRomanisation
    }).some((route: RouteOption) => route.inDrawer);
}