import { useEffect } from 'react';

export const useSeasonalBackground = (backgroundSeasonal: boolean) => {
  useEffect(() => {
    document.body.classList.remove('spring', 'summer', 'autumn', 'winter', 'default');

    if (backgroundSeasonal) {
      const today = new Date();
      const year = today.getFullYear();

      const seasons = [
        { start: new Date(year, 2, 20), end: new Date(year, 5, 20), class: 'spring' },
        { start: new Date(year, 5, 21), end: new Date(year, 8, 22), class: 'summer' },
        { start: new Date(year, 8, 23), end: new Date(year, 11, 21), class: 'autumn' },
        { start: new Date(year, 11, 22), end: new Date(year, 2, 19), class: 'winter' }
      ];

      for (let season of seasons) {
        if (today >= season.start && today <= season.end) {
          document.body.classList.add(season.class);
          break;
        }
      }
    } else {
      document.body.classList.add('default');
    }
  }, [backgroundSeasonal]);
};
